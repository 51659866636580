import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import "../Styles/Pages.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Styles/Book.css";
import { useFirestoreWriter } from "../utils/useFirestoreWriter";
import useFirestoreCollection from "../utils/useFirestoreCollection";

export default function Book() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [currentTime] = useState(new Date());
  const [persons, setPersons] = useState("");
  const {
    loading: adminLoading,
    error: adminError,
    data: adminData,
  } = useFirestoreCollection("administration"); //change to administration for production or testAdmin for testing
  const [closingDate, setClosingDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (adminData && adminData.length > 0) {
      setClosingDate(adminData[0].closingDate.toDate());
    }
  }, [adminData]);

  const { addDocument } = useFirestoreWriter("reservations");
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    addDocument({
      to: [
        "p_iliakop@yahoo.gr",
        "mariaperou@hotmail.com",
        "simiakos3@gmail.com",
      ],
      message: {
        subject: "Νέα κράτηση!",
        html: `<p>Έχετε μια νέα κράτηση από τον/την<strong> ${fullName}</strong>!</p></br>
        <p>Για<strong> ${persons} άτομα</strong> στις<strong> ${dateAndTime.toLocaleString()}</strong>.</p></br>
        <p>Το email του χρήστη είναι ${email}.</p><br/><br/><br/>
        <p>Η κράτηση έγινε στις ${new Date().toLocaleString()}.</p>`,
      },
      Name: fullName,
      Email: email,
      Date: dateAndTime,
      Date_created: new Date(),
      Persons: persons,
    });
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      navigate("/");
    }, 3500);
  };
  return (
    <div className="book_form page_content">
      <Header />
      {loading && <p>Loading...</p>}
      {success ? (
        <div className="success">
          <h3>Thank you!</h3>
          <p>Your reservation has been successfully submitted!</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <div className="dateAndTime">
            <label className="dateAndTimeLabel">Date and Time:</label>
            <DatePicker
              selected={dateAndTime}
              onChange={function (date) {
                setDateAndTime(date);
              }}
              showTimeSelect
              dateFormat="d MMMM, yyyy HH:mm "
              timeFormat="HH:mm"
              minDate={new Date()}
              maxDate={new Date(closingDate)}
              minTime={
                dateAndTime &&
                currentTime.getHours() <= dateAndTime &&
                currentTime.getHours() > 19 &&
                dateAndTime.getDate() === currentTime.getDate()
                  ? new Date().setHours(currentTime.getHours() + 1, 0)
                  : new Date().setHours(15, 0)
              }
              maxTime={new Date().setHours(22, 0)}
              timeIntervals={30}
              shouldCloseOnSelect={true}
              closeOnSelect={true}
              required
            />
          </div>
          <label>
            Persons:
            <input
              type="number"
              value={persons}
              onChange={(e) => setPersons(e.target.value)}
              required
              min="1"
            />
          </label>
          <button type="submit">Book your table</button>
        </form>
      )}
      <Footer />
    </div>
  );
}
