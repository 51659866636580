import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDFVN_OMV2tgzCksWPyR63RibI50UKFnSw",
  authDomain: "oldhouse-7bfc8.firebaseapp.com",
  projectId: "oldhouse-7bfc8",
  storageBucket: "oldhouse-7bfc8.appspot.com",
  messagingSenderId: "809140133691",
  appId: "1:809140133691:web:00294b8a1f82f636fda11e",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, app };
