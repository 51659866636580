import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const useFirestoreWriter = (collectionName) => {
  const addDocument = async (document) => {
    try {
      const docRef = await addDoc(collection(db, collectionName), document);
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return { addDocument };
};

export { useFirestoreWriter };
