import React, { useEffect, useState } from "react";
import "../Styles/OurNews.css";
import useFirestoreCollection from "../utils/useFirestoreCollection";

const OurNews = () => {
  const [news, setNews] = useState([]);
  const {
    loading: adminLoading,
    error: adminError,
    data: adminData,
  } = useFirestoreCollection("administration"); //change to administration for production or testAdmin for testing

  useEffect(() => {
    if (adminData && adminData.length > 0) {
      setNews(adminData[0].news);
    }
  }, [adminData]);

  return (
    <div className="our-news">{adminLoading ? <p></p> : <p>{news}</p>}</div>
  );
};

export default OurNews;
