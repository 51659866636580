import "../Styles/Header.css";
import SocialIcons from "./SocialIcons";
import { ReactComponent as Logo } from "../Images/oldlogo.svg";
import BookNowIcon from "./BookNowIcon";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useFirestoreCollection from "../utils/useFirestoreCollection";

function Header() {
  const [isClosed, setIsClosed] = useState(true);
  const [bookings, setBookings] = useState(false);
  const location = useLocation();
  const isBookPage = location.pathname === "/book";
  const {
    loading: adminLoading,
    error: adminError,
    data: adminData,
  } = useFirestoreCollection("administration"); //change to administration for production or testAdmin for testing

  useEffect(() => {
    if (adminData && adminData.length > 0) {
      setIsClosed(adminData[0].isWinter);
      setBookings(adminData[0].bookings);
    }
  }, [adminData]);

  return (
    <div className="header">
      <Link to="/" className="logo_link">
        <Logo className="logo" />
      </Link>
      {!isClosed && !isBookPage && bookings ? <BookNowIcon /> : null}
      <SocialIcons />
    </div>
  );
}

export default Header;
