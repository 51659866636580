import { BsFacebook, BsInstagram, BsTwitterX } from "react-icons/bs";

import { FaTripadvisor, FaTiktok } from "react-icons/fa";
import "../Styles/SocialIcons.css";

function SocialIcons() {
  return (
    <div className="social_media">
      <a href="https://www.facebook.com/oldhouseafantou">
        <BsFacebook className="icons" size={25} />
      </a>
      <a href="https://www.instagram.com/oldhouseafantou/">
        <BsInstagram className="icons" size={24} />
      </a>
      <a href="https://www.tiktok.com/@oldhouseafantou">
        <FaTiktok className="icons" size={24} />
      </a>
      <a href="https://www.tripadvisor.com/Restaurant_Review-g488296-d2175648-Reviews-The_Old_House_Restaurant-Afandou_Rhodes_Dodecanese_South_Aegean.html">
        <FaTripadvisor className="icons" size={29} />
      </a>
    </div>
  );
}

export default SocialIcons;
