import React from "react";
import "../Styles/AboutUs.css";

function AboutUs() {
  return (
    <div className="aboutUs">
      <h2>About Us</h2>
      <p>
        Welcome to <strong>The Old House Restaurant</strong>, a family-run
        establishment in Afantou, Rhodes, Greece. <strong>Since 1986</strong>,
        we have been serving up delicious
        <strong> Greek-Rhodian traditional cuisine</strong>, with a focus on
        using <strong>locally-sourced ingredients</strong>. Our restaurant is
        named after the old houses of Rhodes, and we have decorated it to
        resemble those charming homes. Dine on our terrace area, surrounded by
        beautiful plants and flowers, <strong>enjoy</strong> the authentic
        ambiance of our restaurant and<strong> experience</strong> the local
        warmth and hospitality. Come visit us and{" "}
        <strong>taste the flavors of Rhodes</strong> in a unique and
        unforgettable setting.
      </p>
    </div>
  );
}

export default AboutUs;
