import React from "react";
import { FaTools } from "react-icons/fa";
import SocialIcons from "./SocialIcons";
import "../Styles/UnderConstruction.css";

const UnderConstruction = () => {
  return (
    <div className="construction-container">
      <FaTools className="construction-icon" />
      <h2>Site Under Maintenance</h2>
      <p>
        Sorry, this site is currently under maintenance. Please check back
        soon!
      </p>
      <div className="social-icons">
        <p>Meanwhile follow our social media for updates: </p>
        <SocialIcons />
      </div>
    </div>
  );
};

export default UnderConstruction;
