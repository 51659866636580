import "./App.css";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Book from "./Pages/Book";
import FindUs from "./Pages/FindUs";
import AboutPage from "./Pages/AboutPage";
import AdminLogin from "./Pages/AdminLogin";
import AdminPage from "./Pages/AdminPage";

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book" element={<Book />} />
          <Route path="/findus" element={<FindUs />} />
          <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/admin" element={<AdminPage/>} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
