import React from "react";
import "../Styles/Pages.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AboutUs from "../Components/AboutUs";
import Return from "../Components/Return";

function AboutPage() {
  return (
    <div className="page_content">
      <Header />
      <AboutUs />
      <Return />
      <Footer />
    </div>
  );
}

export default AboutPage;
