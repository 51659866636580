import React from "react";

const Map = () => {
  return (
    <iframe
      title="Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1607.8460645690752!2d28.17322152290796!3d36.29551939204148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14956f6dbae1eb4d%3A0x71bc50b507db3e14!2zzpXPg8-EzrnOsc-Ez4zPgc65zr8gVGhlIG9sZCBob3VzZSByZXN0YXVyYW50!5e0!3m2!1sel!2sgr!4v1681751112296!5m2!1sel!2sgr"
      width="430"
      height="450"
      style={{ border: 0 }}
      loading="eager"
    ></iframe>
  );
};

export default Map;
