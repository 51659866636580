import React from 'react';
import '../Styles/CustomCard.css';

const CustomCard = ({ title, content, image }) => {
  return (
    <div className="custom-card">
      <div className="custom-card-image">
        <img src={image} alt="" />
        <div className="custom-card-title-container">
          <h3 className="custom-card-title">{title}</h3>
        </div>
        <div className="custom-card-content">
          <div className="custom-card-text">{content}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default CustomCard;