import React from "react";
import "../Styles/Footer.css";
import { BsMessenger, BsGeoAltFill, BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  // Check if the user is on a mobile device
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  // Create a link to send a message to the company's WhatsApp number
  const phoneNumber = "+306946162665";
  const message = " ";
  const encodedMessage = encodeURIComponent(message);
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

  return (
    <div className="footer">
      <div className="footer_content">
        <div className="footer_content_left">
          <h3 className="footer_titles">Contact us</h3>
          {isMobile ? (
            <div className="footer_contact_icons">
              <a
                href="https://m.me/332846666816250"
                target="_blank"
                rel="noreferrer"
              >
                <BsMessenger
                  className="footer_buttons"
                  size={32}
                  fill="rgb(250, 210, 162)"
                />
              </a>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <BsWhatsapp
                  className="footer_buttons"
                  size={32}
                  fill="rgb(250, 210, 162)"
                />
              </a>
            </div>
          ) : (
            <div className="footer_contact_icons">
              <a
                href="https://m.me/332846666816250"
                target="_blank"
                rel="noreferrer"
              >
                <BsMessenger
                  className="footer_buttons"
                  size={32}
                  fill="rgb(250, 210, 162)"
                />
              </a>
            </div>
          )}
        </div>
        <div className="footer_content_right">
          {/* show the link to the company's location on Google Maps*/}
          <h3 className="footer_titles">Find us</h3>
          <Link to={"/findus"}>
            <BsGeoAltFill
              className="footer_buttons"
              size={34}
              fill={"rgb(250, 210, 162)"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
