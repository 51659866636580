import { MdOutlineTableRestaurant } from "react-icons/md";
import "../Styles/BookNowIcon.css";
import { Link } from "react-router-dom";

function BookNowIcon() {
  return (
    <Link to="/book" className="book_now_link">
      <div className="book_now_icon">
        <svg viewBox="0 0 100 100">
          <path
            id="circle-path"
            d="M 20,46 A1 1, 0, 0 1 80 52"
            stroke="none"
            fill="none"
          />
          <text>
            <textPath
              href="#circle-path"
              startOffset="0%"
              textLength={90}
              fill="#622F0A"
            >
              BOOK NOW!
            </textPath>
          </text>
        </svg>
        <MdOutlineTableRestaurant className="table_icon" size={36} />
      </div>
    </Link>
  );
}

export default BookNowIcon;
