import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { app } from "../utils/firebaseConfig.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "../Styles/Pages.css";
import Return from "../Components/Return.js";
import "../Styles/Admin.css";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth(app);

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        navigate("/admin");
      })
      .catch((error) => {
        console.log("Authentication error:", error);
        setIsError(true);
      });
  };
  return (
    <div className="admin_login">
      <h1>Admin Login</h1>
      <form onSubmit={handleLogin}>
        <label htmlFor="email">Email :</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password">Password :</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      {isError && <p>Invalid credentials</p>}
      <Return />
    </div>
  );
};

export default AdminLogin;
