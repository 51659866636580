import React from "react";
import useFirestoreCollection from "../utils/useFirestoreCollection";
import "../Styles/WorkingHours.css";
import { useState, useEffect } from "react";

const WorkingHours = () => {
  const { loading, error, data } = useFirestoreCollection("hours");
  const {
    loading: adminLoading,
    error: adminError,
    data: adminData,
  } = useFirestoreCollection("administration"); //change to administration for production or testAdmin for testing
  const [isWinter, setIsWinter] = useState(null);

  useEffect(() => {
    if (adminData && adminData.length > 0) {
      setIsWinter(adminData[0].isWinter);
    }
  }, [adminData]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && console.log(error)}
      {!loading && !error && (
        <div className="working-hours">
          {!isWinter ? (
            <ul className="working-hours-list">
              {data.map((day) => (
                <li key={day.id}>
                  <span className="day">{day.day}:</span>
                  {day.isOpen ? (
                    <span className="opening-time">{day.opening}</span>
                  ) : (
                    <span className="closed">Closed</span>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>
              The restaurant is currently<strong> CLOSED</strong> for the winter
              season.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkingHours;
