import React from "react";
import "../Styles/Return.css";
import { TiArrowBackOutline } from "react-icons/ti";
import { NavLink } from "react-router-dom";
function Return() {
  return (
    <div>
      <NavLink
        to="/"
        activeClassName="active"
        className="nav-link"
        activeStyle={{ color: "black" }}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <h3>
          <TiArrowBackOutline size={26} />
          Return
        </h3>
      </NavLink>
    </div>
  );
}

export default Return;
