import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import "../Styles/Pages.css";
import "../Styles/Home.css";
import Footer from "../Components/Footer";
import OurNews from "../Components/OurNews";
import UnderConstruction from "../Components/UnderConstruction";
import WorkingHours from "../Components/WorkingHours";
import useFirestoreCollection from "../utils/useFirestoreCollection";
import { NavLink } from "react-router-dom";
import CustomCard from "../Components/CustomCard";
import AboutUsImg from "../Images/backgroundUs.jpg";
import WorkingHoursImg from "../Images/mushOven.jpg";

function Home() {
  const {
    loading: adminLoading,
    error: adminError,
    data: adminData,
  } = useFirestoreCollection("administration"); //change to administration for production or testAdmin for testing
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    if (adminData && adminData.length > 0) {
      setIsUnderMaintenance(adminData[0].isUnderMaintenance);
    }
  }, [adminData]);

  return (
    <div className="page_content">
      <Header />
      <div className="carousel_working_hours_container">
        <OurNews />
        {isUnderMaintenance ? (
          <UnderConstruction />
        ) : (
          <div className="cards">
            <NavLink
              to="/aboutus"
              //activeClassName="active"
              className="nav-link"
              //activeStyle={{ color: "black" }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CustomCard
                title={"About us"}
                content={"Read more..."}
                image={AboutUsImg}
              />
            </NavLink>
            <CustomCard
              title={"Working hours"}
              content={<WorkingHours />}
              image={WorkingHoursImg}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
